<template>
  <div
    class="onboarding-wizard-idea-cover-wrapper font-outfit-light"
    :style="{ backgroundImage: `url(${cover})`}"
    @mouseover="tooltipVisible = true"
    @mouseleave="tooltipVisible = false"
  >
    <div v-show="tooltipVisible" class="tooltip">
      {{ $t('pages.onBoarding.step04.tooltipText') }}
    </div>
    <img class="cover-icon" :src="icon" alt="alt" width="40">
  </div>
</template>

<script>
export default {
  name: 'OnboardingWizardIdeaCover',

  props: {
    cover: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      tooltipVisible: false
    }
  }
}
</script>

<style scoped lang="scss">
.onboarding-wizard-idea-cover-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 270px;
  background-position: center;
  background-size: contain;
  border-radius: 8px;
  border: 1px solid $color-border;
  padding: 0 10px;
  justify-content: center;

  .cover-icon {
    position: absolute;
    bottom: 10px;
    left: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    padding: 5px;
  }

  .tooltip {
    background-color: #081E4A;
    border-radius: 8px;
    padding: 10px;
    color: #fff;
    text-align: center;
    align-self: center;
    font-size: 12px;
  }
}
</style>
