<template>
  <div>
    <v-row>
      <v-col :cols="12">
        <div class="image" />
        <onboarding-wizard-heading
          :title="$t('pages.onBoarding.step05.financialSettings')"
          :description="$t('pages.onBoarding.step05.chooseTheStartDate')"
          text-center
          :show-tick-mark="isTickMarkShow"
          top-space="0"
          class="mb-8"
        />
      </v-col>
    </v-row>

    <v-row dense class="mb-2">
      <v-col :cols="12">
        <v-menu
          v-model="dateStartingFromMenuVisible"
          :close-on-content-click="false"
          offset-y
          max-width="243px"
          min-width="243px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              readonly
              :value="formatDateForStartingFrom"
              :label="$t('pages.financialPlan.settings.planningStart')"
              outlined
              hide-details
              :append-icon="icons.mdiCalendarMonthOutline"
              v-bind="attrs"
              dense
              height="46px"
              v-on="on"
              @click:append="dateStartingFromMenuVisible = true"
            />
          </template>
          <v-date-picker
            v-model="localData.planingStart"
            :width="243"
            color="primary"
            type="month"
            :min="minDate"
            :max="maxDate"
            scrollable
            dense
            height="46px"
            @change="dateStartingFromMenuVisible = false"
          />
        </v-menu>
      </v-col>
      <v-col :cols="12">
        <v-select
          v-model="localData.planingPeriod"
          :label="$t('pages.ideaSettings.planningPeriod')"
          :items="planningForOptions"
          item-value="value"
          item-text="label"
          outlined
          :menu-props="{offsetY: true}"
          dense
          height="46px"
          hide-details
        />
      </v-col>
      <v-col :cols="12">
        <v-autocomplete
          v-model="localData.currency"
          :label="$t('pages.financialPlan.settings.currency')"
          :items="currencies"
          item-value="code"
          item-text="name"
          outlined
          hide-details
          dense
          height="46px"
        >
          <template #item="{item}">
            <span style="font-size: 13px">{{ item.name }} ({{ item.symbol }})</span>
          </template>
          <template #selection>
            <span>{{ selectedCurrency.name }} ({{ selectedCurrency.symbol }})</span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import MixinCurrencies from '@/mixins/currencies'
import OnboardingWizardHeading from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardHeading.vue'
import _find from 'lodash/find'
import { mdiCalendarMonthOutline } from '@mdi/js'

export default {
  name: 'OnboardingFinancialSettings',

  components: { OnboardingWizardHeading },

  mixins: [MixinCurrencies],

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          planingStart: '',
          planingPeriod: '',
          currency: null
        }
      }
    }
  },

  data () {
    return {
      dateStartingFromMenuVisible: false,
      localData: {
        planingStart: '',
        planingPeriod: '',
        currency: null
      },
      icons: { mdiCalendarMonthOutline }
    }
  },

  computed: {
    isTickMarkShow () {
      return this.localData.planingStart !== null && this.localData.planingPeriod !== null && this.localData.currency !== ''
    },

    selectedCurrency () {
      return _find(this.currencies, ['code', this.localData.currency])
    },

    planningForOptions () {
      return [
        {
          value: 1,
          label: this.$t('oneYear')
        },
        {
          value: 2,
          label: this.$t('twoYear')
        },
        {
          value: 3,
          label: this.$t('threeYear')
        },
        {
          value: 5,
          label: this.$t('fiveYear')
        }
      ]
    },

    formatDateForStartingFrom () {
      return this.localData.planingStart ? this.$moment(this.localData.planingStart).format('MMMM, yyyy') : null
    },

    minDate () {
      return this.$moment().startOf('year').subtract(3, 'y').format('YYYY-MM-DD')
    },

    maxDate () {
      return this.$moment().startOf('year').add(4, 'y').format('YYYY-MM-DD')
    }
  },

  created () {
    this.localData = this.data
  },

  mounted () {
    EventBus.$on('onboarding-form-changed', (payload) => {
      this.localData = payload
    })
  },

  beforeDestroy () {
    EventBus.$off('onboarding-form-changed')
  }
}
</script>

<style scoped lang="scss">
.image {
  background-image: url('~@/assets/img/onboarding/financialSettings.svg');
  width: 100%;
  min-height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 20px;
}
</style>
