<template>
  <div>
    <v-row>
      <v-col v-if="isUseAi" :cols="12">
        <onboarding-wizard-heading
          :title="$t('pages.onBoarding.step02.whatIsYourBrilliantIdea')"
          :description="$t('pages.onBoarding.step02.describeYourIdea')"
          :show-tick-mark="localData.description !== ''"
          top-space="0"
        />
        <v-textarea
          v-model="localData.description"
          :placeholder="$t('pages.onBoarding.step02.placeholderText')"
          outlined
          hide-details
          height="75px"
          no-resize
        />
      </v-col>

      <v-col v-if="isUseAi" :cols="12">
        <div class="see-examples-wrapper">
          <onboarding-see-examples
            v-model="seeExamplesVisible"
            :items="[
              'pages.onBoarding.step02.example01',
              'pages.onBoarding.step02.example02',
              'pages.onBoarding.step02.example03'
            ]"
          />
        </div>
      </v-col>

      <v-col v-if="isUseAi" :cols="12">
        <onboarding-wizard-heading
          :title="$t('pages.onBoarding.step02.whereWillYourBusinessBeBased')"
          :description="$t('pages.onBoarding.step02.enterTheCountryAndCity')"
          :show-tick-mark="localData.country !== null"
        />
        <v-row dense>
          <v-col :cols="12" :md="6">
            <v-autocomplete
              v-model="localData.country"
              :items="countries"
              item-value="code"
              item-text="name"
              :label="$t('pages.onBoarding.step02.country')"
              outlined
              hide-details
              dense
              height="46px"
            >
              <!--              :prepend-inner-icon="icons.mdiMapMarkerOutline"-->
              <template #prepend-inner>
                <v-icon size="18" class="mt-1">
                  {{ icons.mdiMapMarkerOutline }}
                </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col :cols="12" :md="6">
            <v-text-field
              v-model="localData.city"
              outlined
              :label="$t('pages.onBoarding.step02.city')"
              hide-details
              dense
              height="46px"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col>
        <onboarding-wizard-heading
          :title="$t('pages.onBoarding.step02.whatStageIsYourBusiness')"
          :description="isUseAi ? $t('pages.onBoarding.step02.selectTheStage') : $t('pages.onBoarding.step02.selectTheStageWithoutAi')"
          :show-tick-mark="localData.ideaStage !== ''"
        />
        <div class="d-flex mb-2">
          <template v-for="(item, index) in stages">
            <onboarding-idea-stage-card
              :key="index"
              :title="`${$t(item.title)}`"
              :type="item.type"
              :img="item.img"
              :active="localData.ideaStage === item.type"
              @click="localData.ideaStage = $event"
            />
          </template>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import OnboardingIdeaStageCard from '@/views/Onboarding/Components/OnboardingIdeaStageCard.vue'
import OnboardingSeeExamples from '@/views/Onboarding/Components/OnboardingSeeExamples.vue'
import OnboardingWizardHeading from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardHeading.vue'
import { mdiMapMarkerOutline } from '@mdi/js'

export default {
  name: 'OnboardingSecondStep',

  components: { OnboardingSeeExamples, OnboardingIdeaStageCard, OnboardingWizardHeading },

  inject: ['ideaStages', 'getCountries', 'getIsUseAi'],

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          description: '',
          city: '',
          country: null,
          ideaStage: null
        }
      }
    }
  },

  data () {
    return {
      localData: {
        description: '',
        location: '',
        ideaStage: null
      },
      icons: { mdiMapMarkerOutline },
      seeExamplesVisible: false
    }
  },

  computed: {
    stages () {
      return this.ideaStages()
    },

    countries () {
      return this.getCountries()
    },

    isUseAi () {
      return this.getIsUseAi()
    }
  },

  created () {
    this.localData = this.data
  },

  mounted () {
    EventBus.$on('onboarding-form-changed', (payload) => {
      this.localData = payload
    })
  },

  beforeDestroy () {
    EventBus.$off('onboarding-form-changed')
  }
}
</script>

<style scoped lang="scss">

  .see-examples-wrapper {
    border-radius: 8px;
    background: rgb(14, 100, 230, .12);
    padding: 8px 12px;
  }

</style>
