<template>
  <ib-basic-expander v-model="show" :title="$t('pages.onBoarding.step02.seeExamples')">
    <ul class="see-examples-list-wrapper mt-1">
      <li v-for="(item, index) in items" :key="index">
        {{ $t(item) }}
      </li>
    </ul>
  </ib-basic-expander>
</template>

<script>
import IbBasicExpander from '@/components/_v2/IbBasicExpander.vue'

export default {
  name: 'OnboardingSeeExamples',

  components: { IbBasicExpander },

  props: {
    value: {
      type: Boolean,
      required: true
    },

    items: {
      type: Array,
      required: true
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.see-examples-list-wrapper {
  li {
    font-size: 14px;
    opacity: .85;
    color: $color-primary;
  }
}
</style>
