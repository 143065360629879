<template>
  <div>
    <div class="text-center mt-8 mb-8">
      <onboarding-avatar-placeholder class="m-auto" :text="initials" />
    </div>
    <onboarding-wizard-heading
      :title="$t('pages.onBoarding.step01.welcomeToIdeaBuddy')"
      :description="$t('pages.onBoarding.step01.setUpYourProfileName')"
      text-center
      :show-tick-mark="localData.firstName !== '' && localData.lastName !== ''"
      top-space="0"
      class="text-wrapper"
    />

    <v-row
      dense
      class="mb-4 input-wrapper"
    >
      <v-col :cols="12" :sm="6">
        <v-text-field
          v-model="localData.firstName"
          outlined
          hide-details
          dense
          height="46px"
          :label="$t('firstName')"
        />
      </v-col>
      <v-col :cols="12" :sm="6">
        <v-text-field
          v-model="localData.lastName"
          outlined
          hide-details
          dense
          height="46px"
          :label="$t('lastName')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import EventBus from '@/event-bus'
import OnboardingAvatarPlaceholder from '@/views/Onboarding/Components/OnboardingAvatarPlaceholder.vue'
import OnboardingWizardHeading from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardHeading.vue'

export default {
  name: 'OnboardingUserDetails',

  components: {
    OnboardingAvatarPlaceholder,
    OnboardingWizardHeading
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          avatar: '',
          firstName: '',
          lastName: ''
        }
      }
    }
  },

  data () {
    return {
      localData: {
        avatar: '',
        firstName: '',
        lastName: ''
      }
    }
  },

  computed: {
    initials () {
      const initials = `${this.localData.firstName.charAt(0)}${this.localData.lastName.charAt(0)}`

      return initials.toUpperCase()
    }
  },

  created () {
    this.localData = this.data
  },

  mounted () {
    EventBus.$on('onboarding-form-changed', (payload) => {
      this.localData = payload
    })
  },

  beforeDestroy () {
    EventBus.$off('onboarding-form-changed')
  }
}
</script>

<style scoped lang="scss">
.v-text-field--outlined >>> fieldset {
  border-color: red;
}

.text-wrapper {
  margin-bottom: 35px;
}

.input-wrapper {
  max-width: 376px;
  margin: 0 auto;
}

</style>
