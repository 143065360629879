<template>
  <ib-card
    :animation="false"
    class="wizard-wrapper"
  >
    <slot />
  </ib-card>
</template>

<script>
export default {
  name: 'OnboardingWrapper'
}
</script>

<style scoped lang="scss">
.wizard-wrapper {
  padding: 20px;

  @include media-breakpoint-up($md) {
    padding: 40px 40px 20px;
  }
}
</style>
