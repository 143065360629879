<template>
  <v-app>
    <!-- Onboarding Service Provider -->
    <onboarding-service-provider
      v-slot="{
        currentStep,
        nextStep,
        backStep,
        isFirstStep,
        isLastStep,
        form,
        validation,
        finish,
        loaders,
        categories,
        containerWidth,
        firstTime,
        numberOfSteps
      }"
    >
      <div class="onboarding-container">
        <img v-if="firstTime" src="@/assets/img/logos/ideaBuddy-color.svg" class="onboarding-page-logo" alt="logo">
        <div class="onboarding-container-box-wrapper">
          <div :style="{'width': containerWidth}">
            <loader v-if="loaders.general" />
            <template v-else>
              <div v-if="!firstTime" class="text-center">
                <p class="ma-0 create-new-idea-title font-outfit-regular">
                  {{ $t('pages.onBoarding.createANewIdea') }}
                </p>
              </div>
              <!-- Onboarding Wizard Stepper -->
              <onboarding-wizard-stepper
                class="my-5"
                :steps="numberOfSteps"
                :hide-percentage-text="!firstTime"
                :current-step="!firstTime ? currentStep - 1 : currentStep"
              />
              <!-- Onboarding Wizard Stepper -->

              <!-- Onboarding Wizard Wrapper -->
              <onboarding-wizard-wrapper>

                <div v-show="currentStep === 0">
                  <onboarding-user-details :data="form" />
                </div>

                <div v-show="currentStep === 1">
                  <onboarding-type-step :data="form" />
                </div>

                <div v-show="currentStep === 2">
                  <onboarding-second-step :data="form" />
                </div>

                <div v-show="currentStep === 3">
                  <onboarding-third-step :data="form" :categories="categories" />
                </div>

                <div v-show="currentStep === 4">
                  <onboarding-fourth-step :data="form" />
                </div>

                <div v-show="currentStep === 5">
                  <onboarding-financial-settings :data="form" />
                </div>

                <!-- Onboarding Wizard Footer -->
                <onboarding-wizard-footer
                  :validation="validation"
                  :is-first-step="isFirstStep"
                  :is-last-step="isLastStep"
                  :loading="loaders.saving"
                  @next="nextStep"
                  @back="backStep"
                  @back-to-app="$router.push({name: prevRouteName})"
                  @finish="finish"
                />
                <!-- Onboarding Wizard Footer -->
              </onboarding-wizard-wrapper>
              <!-- Onboarding Wizard Wrapper -->
            </template>
          </div>
        </div>
      </div>
    </onboarding-service-provider>
    <!-- Onboarding Service Provider -->
  </v-app>
</template>

<script>
import Loader from '@/components/Loader.vue'
import OnboardingFinancialSettings from '@/views/Onboarding/Components/OnboardingFinancialSettings.vue'
import OnboardingFourthStep from '@/views/Onboarding/Components/OnboardingFourthStep.vue'
import OnboardingSecondStep from '@/views/Onboarding/Components/OnboardingSecondStep.vue'
import OnboardingServiceProvider from '@/components/ServiceProviders/OnboardingServiceProvider.vue'
import OnboardingThirdStep from '@/views/Onboarding/Components/OnboardingThirdStep.vue'
import OnboardingTypeStep from '@/views/Onboarding/Components/OnboardingTypeStep.vue'
import OnboardingUserDetails from '@/views/Onboarding/Components/OnboardingUserDetails.vue'
import OnboardingWizardFooter from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardFooter.vue'
import OnboardingWizardStepper from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardStepper.vue'
import OnboardingWizardWrapper from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardWrapper.vue'

export default {
  name: 'OnboardingPage',

  components: {
    OnboardingTypeStep,
    OnboardingFourthStep,
    OnboardingThirdStep,
    Loader,
    OnboardingFinancialSettings,
    OnboardingSecondStep,
    OnboardingWizardFooter,
    OnboardingWizardStepper,
    OnboardingUserDetails,
    OnboardingWizardWrapper,
    OnboardingServiceProvider
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.route = from
    })
  },

  data () {
    return {
      route: null
    }
  },

  computed: {
    prevRouteName () {
      return this.route.name ? this.route.name : 'idea-collection'
    }
  }
}
</script>

<style scoped lang="scss">
.onboarding-container {
  background-color: #F5F6FA;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding: 5% 0 21%;
  overflow-y: scroll;

  @include media-breakpoint-up($sm) {
    padding: 5% 0 12%;
  }

  @include media-breakpoint-up($md) {
    justify-content: flex-start;
    padding: 15px 0;
  }

  .onboarding-page-logo {
    width: 130px;
    margin-bottom: 25px;
  }

  .onboarding-container-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    width: 90%;
    min-width: 300px;

    @include media-breakpoint-up($md) {
      align-items: center;
      margin-bottom: 80px;
    }

    .create-new-idea-title {
      font-size: 28px;
    }
  }
}
</style>
