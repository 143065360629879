<script>
import { mapActions, mapGetters } from 'vuex'
import EventBus from '@/event-bus'
import MixinCountries from '@/mixins/countries'
import Vue from 'vue'
import VueConfetti from 'vue-confetti'
import _find from 'lodash/find'
import _omit from 'lodash/omit'
import _sortBy from 'lodash/sortBy'
import ideaStages from '@/data/ideaStages'

import OnboardingTypesEnum from '@/constants/OnboardingTypesEnum'
import { scrollToTop } from '@/helpers/scrollHelpers'

Vue.use(VueConfetti)
export default {
  name: 'OnboardingServiceProvider',

  mixins: [MixinCountries],

  provide () {
    return {
      selectedCategory: () => this.selectedCategory,
      hasCategoryTemplate: () => this.hasCategoryTemplate,
      currentLang: () => this.currentLang,
      ideaStages: () => ideaStages,
      getCountries: () => this.countries,
      getFirstTime: () => this.firstTime,
      getCompletedPercentage: () => this.completedPercentage,
      getCurrentCompletedPercentage: () => this.currentCompletedPercentage,
      getIsQuickStart: () => this.isQuickStart,
      getIsUseAi: () => this.form.useAi,
      getQuickStartInitiate: () => this.quickStartInitiate
    }
  },

  data () {
    return {
      loaders: {
        general: false,
        saving: false
      },
      currentStep: 0,
      catalogue: [],
      quickStartInitiate: false,
      currentCompletedPercentage: 0,
      form: {
        firstName: '',
        lastName: '',
        description: '',
        city: '',
        country: null,
        ideaStage: '',
        category: null,
        ideaName: '',
        ideaSlogan: '',
        planingStart: null,
        planingPeriod: null,
        currency: '',
        useTemplate: null,
        onboardingType: null,
        useAi: null
      }
    }
  },

  computed: {
    ...mapGetters('ideaCollection', ['getIdeasCount']),
    ...mapGetters('idea', ['getCatalogueIdeaTemplate']),
    ...mapGetters('user', ['getIsFirstTime']),

    firstTime () {
      return this.getIsFirstTime
    },

    hasFirstNameAndLastName () {
      return !!(this.$store.state.user.firstName && this.$store.state.user.lastName)
    },

    totalSteps () {
      return 5
    },

    isFirstStep () {
      return this.currentStep === (this.firstTime ? 0 : 1)
    },

    isLastStep () {
      return this.currentStep === this.totalSteps
    },

    isQuickStart () {
      if (this.form.onboardingType === null) return null

      return this.form.onboardingType === OnboardingTypesEnum.QUICK_START
    },

    selectedCategory () {
      if (!this.form.category) return null

      return _find(this.categories, ['id', this.form.category])
    },

    hasCategoryTemplate () {
      if (!this.selectedCategory) return false

      if (!this.selectedCategory.templates.length) return false

      return !!_find(this.selectedCategory.templates, ['lang', this.currentLang])
    },

    template () {
      if (!this.hasCategoryTemplate) return null

      return _find(this.selectedCategory.templates, ['lang', this.currentLang])
    },

    isFistStepValid () {
      return this.form.firstName !== '' && this.form.lastName !== ''
    },

    isSecondStepValid () {
      if (!this.form.useAi) {
        return this.form.ideaStage !== ''
      }

      return this.form.description !== '' && this.form.country !== null && this.form.ideaStage !== ''
    },

    isThirdStepValid () {
      if (this.template) {
        return this.form.category !== null && this.form.useTemplate !== null
      }

      return this.form.category !== null
    },

    isFourthStepValid () {
      return this.form.ideaName !== ''
    },

    isFinancialSettingsStepValid () {
      return this.form.planingStart !== null && this.form.planingPeriod !== null && this.form.currency !== ''
    },

    isOnboardingTypeStepValid () {
      if (this.form.onboardingType === OnboardingTypesEnum.FULL) {
        return this.form.useAi !== null
      }

      return this.form.onboardingType !== null
    },

    validation () {
      if (this.currentStep === 0) {
        return this.isFistStepValid
      }

      if (this.currentStep === 1) {
        return this.isOnboardingTypeStepValid
      }

      if (this.currentStep === 2) {
        return this.isSecondStepValid
      }

      if (this.currentStep === 3) {
        return this.isThirdStepValid
      }

      if (this.currentStep === 4) {
        return this.isFourthStepValid
      }

      if (this.currentStep === 5) {
        return this.isFinancialSettingsStepValid
      }

      return false
    },

    categories () {
      const items = []

      if (this.catalogue.length) {
        const catalogue = _sortBy(this.catalogue, ['position'])

        catalogue.forEach(item => {
          items.push({ header: item.options[this.currentLang].name })

          for (const index of Object.keys(item.children)) {
            items.push(item.children[index])
          }
        })
      }

      return items
    },

    currentLang () {
      return localStorage.getItem('lang')
    },

    containerWidth () {
      const widths = ['500px', '515px', '600px', '580px', '780px', '500px']

      return widths[this.currentStep]
    },

    completedPercentage () {
      let object = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        description: this.form.description,
        country: this.form.country,
        ideaStage: this.form.ideaStage,
        category: this.form.category,
        ideaName: this.form.ideaName,
        planingStart: this.form.planingStart,
        planingPeriod: this.form.planingPeriod,
        currency: this.form.currency
      }

      if (this.form.useAi === false) {
        object = _omit(object, ['description', 'country'])
      }

      const keysLength = Object.keys(object).length
      const doneActivities = Object.values(object).filter(x => x !== null && x !== '').length

      return Number((100 / keysLength) * doneActivities).toFixed()
    },

    numberOfSteps () {
      if (!this.firstTime) {
        return 5
      }

      return 6
    },

    isUsedAi () {
      if (this.form.useAi === null) return false

      return !!this.form.useAi
    }
  },

  watch: {
    form: {
      handler (value) {
        EventBus.$emit('onboarding-form-changed', value)
      },
      deep: true
    },

    completedPercentage: {
      handler (value) {
        if (this.quickStartInitiate) return
        this.currentCompletedPercentage = Number(value)
      }
    },

    currentStep: {
      handler (value, oldValue) {
        scrollToTop('.onboarding-container')
        if (oldValue === 0 && this.firstTime) {
          this.intercomUpdateUser()
        }

        const queryObject = { step: value + 1 }

        if (this.firstTime) {
          queryObject.firstTime = true
        }

        this.$router.replace({ name: 'select-idea', query: queryObject })
      }
    }
  },

  mounted () {
    this.fetchIdeaSettings()
    EventBus.$on('form-data-changed', (payload) => {
      if (payload) {
        this.updateFormData(payload)
      }
    })
  },

  created () {
    if (!this.firstTime) {
      this.currentStep = 1
      this.form.onboardingType = OnboardingTypesEnum.FULL
    } else {
      this.$router.replace({ name: 'select-idea', query: { step: this.currentStep + 1, firstTime: true } })
    }
  },

  beforeDestroy () {
    EventBus.$off('form-data-changed')
  },

  methods: {
    ...mapActions('idea', ['getIdeaSettings']),

    finish () {
      const { firstName, lastName } = this.formatPayload()

      this.loaders.saving = true
      this.$http.post('ideas', this.formatPayload())
        .then((response) => {
          if (response.status === 201) {
            this.$store.commit('user/increaseIdeasCount')
            this.$store.commit('idea/setIdea', { idea: response.data.payload.idea })
            this.$store.commit('ideaCollection/addIdea', response.data.payload.generalInfoIdea)

            this.intercomUpdateUser()
            this.$intercom.trackEvent('Idea created')
            this.$gtm.trackEvent({
              event: 'newIdeaCreated'
            })

            if (this.firstTime) {
              this.$gtm.trackEvent({
                event: 'onboarding-finished'
              })
              this.$gtm.trackEvent({
                event: 'interaction',
                email: this.$store.state.user.email,
                phone: 'Phone',
                address: 'Address'
              })
              this.$confetti.start()
              setTimeout(() => {
                this.$confetti.stop()
              }, 2000)
              setTimeout(() => {
                this.$store.commit('user/setFirstTime', false)
                this.$router.push({ name: 'product-tour' })
              }, 6000)
              this.$store.commit('user/setFirstName', firstName)
              this.$store.commit('user/setLastName', lastName)
            } else {
              this.$router.push({ name: 'product-tour' })
            }
          }
        })
        .catch(() => {
          this.loaders.saving = false
        })
    },

    formatPayload () {
      if (this.isQuickStart) {
        return {
          firstName: this.firstTime ? this.form.firstName : this.$store.state.user.firstName,
          lastName: this.firstTime ? this.form.lastName : this.$store.state.user.lastName,
          name: 'Test Idea',
          currency: 'USD',
          slogan: this.form.ideaSlogan,
          startingFrom: `${this.$moment.utc(Date.now()).format('YYYY-MM')}-01`,
          planningFor: 2,
          catalogueCategoryId: this.selectedCategory.category,
          catalogueIdeaId: this.selectedCategory.id,
          ideaCatalogueCategoryId: this.selectedCategory.category,
          ideaCatalogueIdeaId: this.selectedCategory.id,
          template: !!this.hasCategoryTemplate,
          type: 1,
          catalogueIdeaTemplate: !this.hasCategoryTemplate ? {} : this.template,
          description: this.$t('pages.onBoarding.genericIdeaDescription'),
          stage: ideaStages[0].type,
          country: 'US',
          city: this.form.city
        }
      }

      return {
        firstName: this.firstTime ? this.form.firstName : this.$store.state.user.firstName,
        lastName: this.firstTime ? this.form.lastName : this.$store.state.user.lastName,
        name: this.form.ideaName,
        currency: this.form.currency,
        slogan: this.form.ideaSlogan,
        startingFrom: `${this.form.planingStart}-01`,
        planningFor: this.form.planingPeriod,
        catalogueCategoryId: this.selectedCategory.category,
        catalogueIdeaId: this.selectedCategory.id,
        ideaCatalogueCategoryId: this.selectedCategory.category,
        ideaCatalogueIdeaId: this.selectedCategory.id,
        template: !!this.form.useTemplate,
        type: 1,
        catalogueIdeaTemplate: !this.hasCategoryTemplate ? {} : this.template,
        description: this.form.description,
        stage: this.form.ideaStage,
        country: this.form.country,
        city: this.form.city
      }
    },

    nextStep () {
      if (this.isLastStep) return

      if (this.isQuickStart && this.currentStep === 1) {
        this.quickStartInitiate = true
        this.form.category = Number(process.env.VUE_APP_ONBOARDING_QUICK_START_CATALOGUE_IDEA)
        this.finish()

        return
      }
      this.currentStep = this.currentStep + 1
    },

    backStep () {
      if (this.isFirstStep) return
      this.currentStep = this.currentStep - 1
    },

    updateFormData (data) {
      this.form = data
    },

    async fetchIdeaSettings () {
      try {
        const response = await this.getIdeaSettings()
        this.catalogue = response.catalogue
      } catch (e) {
        this.loading = false
      }
    },

    intercomUpdateUser () {
      const catalogueIdeaTemplate = this.getCatalogueIdeaTemplate

      let data = {
        name: `${this.form.firstName} ${this.form.lastName}`,
        IdeaCount: this.getIdeasCount,
        Template: this.isIdeaFromTemplate,
        QuickStart: this.isQuickStart,
        AIOnboarding: this.isUsedAi
      }

      if (catalogueIdeaTemplate) {
        data = {
          ...data,
          TemplateId: catalogueIdeaTemplate.id,
          TemplateName: catalogueIdeaTemplate.catalogueIdeaName,
          TemplateLang: catalogueIdeaTemplate.lang
        }
      }

      this.$intercom.update(data)
    }
  },

  render () {
    return this.$scopedSlots.default({
      // data
      loaders: this.loaders,
      currentStep: this.currentStep,
      form: this.form,
      onboardingType: this.form.onboardingType,

      // computed
      isFirstStep: this.isFirstStep,
      isLastStep: this.isLastStep,
      validation: this.validation,
      categories: this.categories,
      hasCategoryTemplate: this.hasCategoryTemplate,
      containerWidth: this.containerWidth,
      firstTime: this.firstTime,
      numberOfSteps: this.numberOfSteps,
      completedPercentage: this.completedPercentage,
      isQuickStart: this.isQuickStart,

      // methods
      nextStep: this.nextStep,
      backStep: this.backStep,
      updateFormData: this.updateFormData,
      fetchIdeaSettings: this.fetchIdeaSettings,
      finish: this.finish
    })
  }
}
</script>
