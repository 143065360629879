<template>

  <div class="font-outfit-semi-bold">
    <!--    {{ text }}-->
    <img src="@/assets/img/onboarding/onboarding-user-details-illustration.svg" alt="onboarding user details illustration image">
  </div>

</template>

<script>
export default {
  name: 'OnboardingAvatarPlaceholder',

  props: {
    text: {
      type: String,
      required: true
    }
  }

}
</script>
