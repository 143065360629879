<template>
  <v-row dense class="d-flex">
    <v-col :cols="12">
      <onboarding-wizard-heading
        :title="$t('pages.onBoarding.typeChooser.title')"
        :description="$t('pages.onBoarding.typeChooser.subtitle')"
        :show-tick-mark="content !== null"
      />
    </v-col>
    <v-col :cols="12" :sm="6" class="d-flex">
      <ib-card-selectable
        :title="$t('pages.onBoarding.typeChooser.fullOnboarding.title')"
        :description="$t('pages.onBoarding.typeChooser.fullOnboarding.subtitle')"
        :value="OnboardingTypesEnum.FULL"
        :active="content === OnboardingTypesEnum.FULL"
        :img="require(`@/assets/img/onboarding/full-onboarding.svg`)"
        @click="$emit('input', $event)"
      />
    </v-col>
    <v-col :cols="12" :sm="6" class="d-flex">
      <ib-card-selectable
        :title="$t('pages.onBoarding.typeChooser.quickStart.title')"
        :description="$t('pages.onBoarding.typeChooser.quickStart.subtitle')"
        :value="OnboardingTypesEnum.QUICK_START"
        :active="content === OnboardingTypesEnum.QUICK_START"
        :img="require(`@/assets/img/onboarding/quick-start.svg`)"
        @click="$emit('input', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import IbCardSelectable from '@/components/_v2/IbCardSelectable.vue'
import OnboardingTypesEnum from '@/constants/OnboardingTypesEnum'
import OnboardingWizardHeading from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardHeading.vue'

export default {
  name: 'OnboardingTypeChooser',

  components: { IbCardSelectable, OnboardingWizardHeading },

  props: {
    value: {
      validator: value => typeof value === 'string' || value === null,
      required: true
    }
  },

  data () {
    return {
      OnboardingTypesEnum
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
