<template>
  <div class="template-accordion-wrapper">
    <p class="cursor-pointer font-outfit-regular ma-0" @click="show = !show">
      <span :class="{'el-icon-plus': !show, 'el-icon-minus': show}" />
      {{ title }}
    </p>
    <Transition name="slide">
      <div v-show="show">
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'IbBasicExpander',

  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.template-accordion-wrapper {
  p {
    font-size: 16px;
    color: $color-primary;
  }
  .el-icon-plus,
  .el-icon-minus {
    font-weight: 900 !important;
    font-size: 12px;
    margin-right: 2px;
  }
}
</style>
