export default [
  {
    title: 'pages.onBoarding.step02.idea',
    img: require('@/assets/img/onboarding/idea.svg'),
    type: 1
  },
  {
    title: 'pages.onBoarding.step02.launch',
    img: require('@/assets/img/onboarding/launch.svg'),
    type: 2
  },
  {
    title: 'pages.onBoarding.step02.growth',
    img: require('@/assets/img/onboarding/growth.svg'),
    type: 3
  }
]
