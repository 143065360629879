<template>
  <div>
    <div v-if="suggestions.length" class="mt-3">
      <ai-suggestion-tag
        v-for="(suggestion, index) in suggestions"
        :key="index"
        :ref="`pillSuggestion${index}`"
        class="mr-2"
        size="small"
        :selected="isSuggestionSelected(suggestion)"
        @click="onClickTag(index)"
      >
        {{ suggestion }}
      </ai-suggestion-tag>
    </div>
    <p v-if="notFound">
      {{ notFoundText }}
    </p>
  </div>
</template>

<script>
import AiSuggestionTag from '@/views/Home/StoryMode/Components/AiBuddy/AiSuggestionTag.vue'
import { aiPillAnimation } from '@/helpers/animations'

export default {
  name: 'OnboardingSuggestions',

  components: { AiSuggestionTag },

  props: {
    suggestions: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    notFoundText: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      selected: null,
      notFound: false
    }
  },

  watch: {
    suggestions: {
      handler (value) {
        this.selected = null
        if (!value.length) return
        setTimeout(() => {
          this.suggestions.forEach((item, index) => {
            const element = this.$refs[`pillSuggestion${index}`]
            aiPillAnimation(element[0].$el, index)
          })
        }, 1)
      },
      immediate: true
    }
  },

  methods: {
    onClickTag (index) {
      const suggestion = this.suggestions[index]

      this.selected = suggestion
      this.$emit('click', suggestion)
    },

    isSuggestionSelected (suggestion) {
      return this.selected === suggestion
    }
  }
}
</script>
